import { graphql, useStaticQuery } from "gatsby"

const useContactPage = () => {
  const data = useStaticQuery(graphql`
    {
      coverImage: contentfulAsset(title: { eq: "ContactPage Cover" }) {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
      sketch: contentfulAsset(title: { eq: "leaf" }) {
        gatsbyImageData(
          width: 400
          layout: CONSTRAINED
          placeholder: TRACED_SVG
        )
      }
      bee: contentfulAsset(title: { eq: "Bee" }) {
        gatsbyImageData(width: 50, layout: FIXED, placeholder: TRACED_SVG)
      }
    }
  `)

  return {
    coverImage: data.coverImage.gatsbyImageData,
    sketch: data.sketch.gatsbyImageData,
    bee: data.bee.gatsbyImageData,
  }
}

export default useContactPage
