import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "~/components/seo"
import Container from "~/components/container"

import useContactPage from "~/hooks/use-contact-page"

const NotFoundPage = () => {
  const { sketch } = useContactPage()

  return (
    <>
      <Seo title="404: Not found" />

      <section>
        <Container>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-12 gap-8">
            <div className="xl:col-start-2 xl:col-span-10 prose mx-auto">
              <h1>Page not found</h1>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
          </div>

          <GatsbyImage
            image={sketch}
            className="mt-10 mx-auto w-48"
            alt="HeartBee Flowers · Winchester"
          />
        </Container>
      </section>
    </>
  )
}

export default NotFoundPage
